import { Component, OnInit, EventEmitter, Output, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { EventService } from "../../core/services/event.service";

//Logout
import { environment } from "../../../environments/environment";
import { AuthenticationService } from "../../core/services/auth.service";
import { AuthfakeauthenticationService } from "../../core/services/authfake.service";
import { TokenStorageService } from "../../core/services/token-storage.service";
import { Router } from "@angular/router";

// Language
import { CookieService } from "ngx-cookie-service";
import { LanguageService } from "../../core/services/language.service";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "src/app/account/services/auth.service";

@Component({
  selector: "app-topbar",
  templateUrl: "./topbar.component.html",
  styleUrls: ["./topbar.component.scss"],
})
export class TopbarComponent implements OnInit {
  element: any;
  mode: string | undefined;
  isUserAdmin: boolean = false;
  @Output() mobileMenuButtonClicked = new EventEmitter();

  userData: any;
  userType: string = "";

  constructor(
    @Inject(DOCUMENT) private document: any,
    private eventService: EventService,
    public languageService: LanguageService,
    public _cookiesService: CookieService,
    public translate: TranslateService,
    private authService: AuthService,
    private authFackservice: AuthfakeauthenticationService,
    private router: Router,
    private TokenStorageService: TokenStorageService
  ) {}

  ngOnInit(): void {
    this.userData = this.TokenStorageService.getUser();
    this.element = document.documentElement;
    this.isUserAdmin = this.authService.isUserAdmin();
    this.userType = this.userData.roles[0] ?? "شركة";
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    document.querySelector(".hamburger-icon")?.classList.toggle("open");
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    this.authService.logout();
    // if (environment.defaultauth === 'firebase') {
    //   this.authService.logout();
    // } else {
    //   this.authFackservice.logout();
    // }
    this.router.navigate(["/auth/login"]);
  }

  windowScroll() {
    if (
      document.body.scrollTop > 100 ||
      document.documentElement.scrollTop > 100
    ) {
      //(document.getElementById('back-to-top') as HTMLElement).style.display = "block";
      document.getElementById("page-topbar")?.classList.add("topbar-shadow");
    } else {
      //(document.getElementById("back-to-top") as HTMLElement).style.display =
      ("none");
      document.getElementById("page-topbar")?.classList.remove("topbar-shadow");
    }
  }

  /**
   * Search Close Btn
   */
  closeBtn() {
    var searchOptions = document.getElementById(
      "search-close-options"
    ) as HTMLAreaElement;
    var dropdown = document.getElementById(
      "search-dropdown"
    ) as HTMLAreaElement;
    var searchInputReponsive = document.getElementById(
      "search-options"
    ) as HTMLInputElement;
    dropdown.classList.remove("show");
    searchOptions.classList.add("d-none");
    searchInputReponsive.value = "";
  }
}
