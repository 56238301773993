import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-jobcategories',
  templateUrl: './jobcategories.component.html',
  styleUrls: ['./jobcategories.component.scss']
})
export class JobcategoriesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
