import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CompanyGuard } from "../core/guards/company.guard";
import { AdminGuard } from "../core/guards/admin.guard";
import { AuditorGuard } from "../core/guards/auditor.guard";
import { VerifierGuard } from "../core/guards/verifier.guard";

// Component pages

const routes: Routes = [
  {
    path: "Admin",
    loadChildren: () =>
      import("./admin/admin.module").then((m) => m.AdminModule),
    canActivate: [AdminGuard],
  },
  {
    path: "AdminAcc",
    loadChildren: () =>
      import("./admin-auth/admin-auth.module").then((m) => m.AdminAuthModule),
  },
  {
    path: "Company",
    loadChildren: () =>
      import("./company/company.module").then((m) => m.CompanyModule),
    canActivate: [CompanyGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
