import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  IHttpConnectionOptions,
} from "@microsoft/signalr";
import { click } from "@syncfusion/ej2-angular-grids";
import { ToastrService } from "ngx-toastr";
import { take } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  notifyHubUrl = environment.notifyHubUrl;
  private hubConnection?: HubConnection;
  private hubConnectionNotify?: HubConnection;
  currentUser = JSON.parse(localStorage.getItem("currentUser")!);

  constructor() {}

  createHubConnectionNotify() {
    const options: IHttpConnectionOptions = {
      accessTokenFactory: () => {
        return this.currentUser.token;
      },
    };
    this.hubConnectionNotify = new HubConnectionBuilder()
      .withUrl(this.notifyHubUrl + "", options)
      .withAutomaticReconnect()
      .build();
    this.hubConnectionNotify
      .start()
      .then(() => {
        console.log("Connection established successfully.");
      })
      .catch((error) => {
        console.error("Error establishing connection:", error);
      });
  }

  getCompanyNotify() {
    this.hubConnectionNotify?.on("Notify", (data) => {
      console.log(data);
    });
  }
  // getUserIsOnline() {
  //   this.hubConnection?.on("UserIsOnline", (fullName) => {
  //     this.toastr.info(fullName + " has connected");
  //     console.log(fullName + " has connected");
  //   });
  // }
  // getUserOfline() {
  //   this.hubConnection?.on("UserIsOffline", (fullName) => {
  //     this.toastr.info(fullName + " has connected");
  //     console.log(fullName + " has connected");
  //   });
  // }
  stopHubConnection() {
    this.hubConnection?.stop().catch((error) => console.log(error));
  }
}
