export const environment = {
  production: true,
  defaultauth: "fackbackend",
  firebaseConfig: {
    apiKey: "",
    authDomain: "",
    databaseURL: "",
    projectId: "",
    storageBucket: "",
    messagingSenderId: "",
    appId: "",
    measurementId: "",
  },
  apiUrl: "https://lsc-api.lsc.org.ly",
  notifyHubUrl: "https://lsc-api.lsc.org.ly/NotifyHub",
};
