import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-designed',
  templateUrl: './designed.component.html',
  styleUrls: ['./designed.component.scss']
})

/**
 * Designed Component
 */
export class DesignedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
