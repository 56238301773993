import { MenuItem } from "./menu.model";

export const ADMIN_MENU: MenuItem[] = [
  {
    id: 0,
    label: "MENUITEMS.DASHBOARD.TITLE",
    icon: "ri-dashboard-line",
    Permissions: [
      "Permissions.Dashbaord.View",
      "Permissions.Dashbaord.CreateSubscriptions",
    ],
    link: "Admin/dashboard/subscription-dashboard",
  },
  {
    id: 1,
    label: "القائمة الرئيسية",
    isTitle: true,
  },
  {
    id: 2,
    label: "MENUITEMS.COMPANIES.TITLE",
    icon: "ri-building-line",
    Permissions: [
      "Permissions.Companies.View",
      "Permissions.Companies.Create",
      "Permissions.Companies.Edit",
    ],
    subItems: [
      {
        id: 3,
        label: "MENUITEMS.COMPANIES.ADD",
        link: "Admin/companies/company-details",
        Permissions: [
          "Permissions.Companies.Create",
          "Permissions.Companies.Edit",
        ],
        parentId: 2,
      },
      {
        id: 4,
        label: "MENUITEMS.COMPANIES.LIST",
        link: "Admin/companies/companies-list",
        Permissions: ["Permissions.Companies.View"],
        parentId: 2,
      },
      {
        id: 5,
        label: "MENUITEMS.COMPANIES.PENDING.LIST",
        link: "Admin/companies/pending-companies-list",
        Permissions: ["Permissions.Companies.View"],
        parentId: 2,
      },
      {
        id: 6,
        label: "MENUITEMS.COMPANIES.SUBSCRIPTIONS",
        link: "Admin/companies/subscriptions",
        Permissions: ["Permissions.Companies.View"],
        parentId: 2,
      },
    ],
  },
  {
    id: 6,
    label: "MENUITEMS.INVOICES.TITLE",
    icon: "ri-file-list-3-line",
    Permissions: ["Permissions.Invoices.View", "Permissions.Invoices.Edit"],

    subItems: [
      {
        id: 7,
        label: "MENUITEMS.INVOICES.LIST",
        link: "Admin/invoices/invoices-list",
        Permissions: ["Permissions.Invoices.View"],
        parentId: 5,
      },
      {
        id: 8,
        label: "MENUITEMS.INVOICES.TEMPLATE.LIST",
        link: "Admin/invoices/invoice-templates-list",
        Permissions: ["Permissions.Invoices.View"],
        parentId: 5,
      },
      {
        id: 9,
        label: "MENUITEMS.INVOICES.STOPPED.LIST",
        link: "Admin/invoices/stopped-invoice-list",
        Permissions: ["Permissions.Invoices.Edit"],
        parentId: 5,
      },
    ],
  },
  {
    id: 20,
    label: "MENUITEMS.DEPOSIT_VOUCHERS.TITLE",
    icon: "ri-file-paper-2-line",
    Permissions: [
      "Permissions.DepositVouchers.View",
      "Permissions.DepositVouchers.Review",
      "Permissions.DepositVouchers.Approve",
      "Permissions.DepositVouchers.Verify",
      "Permissions.DepositVouchers.Delete",
    ],

    subItems: [
      {
        id: 21,
        label: "MENUITEMS.DEPOSIT_VOUCHERS.LIST",
        link: "Admin/DepositVouchers/all-deposit-vouchers",
        Permissions: ["Permissions.DepositVouchers.View"],
        parentId: 20,
      },

      {
        id: 22,
        label: "MENUITEMS.DEPOSIT_VOUCHERS.PENDING_LIST",
        link: "Admin/DepositVouchers/pending-deposit-vouchers",
        Permissions: ["Permissions.DepositVouchers.Approve"],

        parentId: 20,
      },

      {
        id: 23,
        label: "MENUITEMS.DEPOSIT_VOUCHERS.VERIFICATION_LIST",
        link: "Admin/DepositVouchers/under-verification-deposit-vouchers",
        Permissions: ["Permissions.DepositVouchers.Verify"],
        parentId: 20,
      },
      {
        id: 24,
        label: "MENUITEMS.DEPOSIT_VOUCHERS.REVIEW_LIST",
        link: "Admin/DepositVouchers/under-review-deposit-vouchers",
        Permissions: ["Permissions.DepositVouchers.Review"],
        parentId: 20,
      },
      {
        id: 25,
        label: "MENUITEMS.DEPOSIT_VOUCHERS.DOWNLOAD",
        link: "Admin/DepositVouchers/download-deposit-vouchers",
        Permissions: ["Permissions.DepositVouchers.View"],
        parentId: 20,
      },
    ],
  },
  {
    id: 10,
    label: "MENUITEMS.ISSUANCES.TITLE",
    icon: "ri-refund-line",
    Permissions: [
      "Permissions.ReceiptVouchers.View",
      "Permissions.Memberships.View",
    ],

    subItems: [
      {
        id: 24,
        label: "MENUITEMS.ISSUANCES.RECEIPTS.FILTERED_LIST",
        link: "Admin/issuances/filtered-reciepts",
        Permissions: ["Permissions.ReceiptVouchers.View"],
        parentId: 10,
      },
      {
        id: 11,
        label: "MENUITEMS.ISSUANCES.RECEIPTS.LIST",
        link: "Admin/issuances/reciepts",
        Permissions: ["Permissions.ReceiptVouchers.View"],
        parentId: 10,
      },
      {
        id: 12,
        label: "MENUITEMS.ISSUANCES.MEMBERSHIP.LIST",
        link: "Admin/issuances/memberships",
        Permissions: ["Permissions.Memberships.View"],
        parentId: 10,
      },
    ],
  },

  {
    id: 13,
    label: "MENUITEMS.SETTINGS.TITLE",
    icon: "ri-tools-line",
    Permissions: [
      "Permissions.SiteSettings.Edit",
      "Permissions.PricingSettings.View",
      "Permissions.DocumentTypesSettings.View",
      "Permissions.CompanyTypeSettings.View",
      "Permissions.ShipSettings.View",
    ],
    subItems: [
      {
        id: 16,
        label: "MENUITEMS.SETTINGS.GENERAL.TITLE",
        link: "Admin/settings/general-settings",
        Permissions: ["Permissions.SiteSettings.Edit"],
        parentId: 13,
      },
      {
        id: 14,
        label: "MENUITEMS.SETTINGS.PRICING.LIST",
        link: "Admin/settings/pricing-settings",
        Permissions: ["Permissions.PricingSettings.View"],
        parentId: 13,
      },

      {
        id: 17,
        label: "MENUITEMS.SETTINGS.DOCUMENT-TYPES.TITLE",
        link: "Admin/settings/document-types-settings",
        Permissions: ["Permissions.DocumentTypesSettings.View"],
        parentId: 13,
      },
      {
        id: 20,
        label: "MENUITEMS.SETTINGS.COMPANY-TYPES.TITLE",
        link: "Admin/settings/company-types-settings",
        Permissions: ["Permissions.CompanyTypeSettings.View"],
        parentId: 13,
      },
      {
        id: 28,
        label: "MENUITEMS.SETTINGS.INVOICE_DETAILS.TITLE",
        link: "Admin/settings/invoice-settings",
        Permissions: ["Permissions.InvoiceSettings.View"],
        parentId: 13,
      },
      {
        id: 15,
        label: "MENUITEMS.SETTINGS.SHIP.LIST",
        link: "Admin/settings/ship-settings",
        Permissions: ["Permissions.ShipSettings.View"],
        subItems: [
          {
            id: 18,
            label: "MENUITEMS.SETTINGS.SHIP-TYPES.TITLE",
            link: "Admin/settings/ship-settings",
            Permissions: ["Permissions.ShipSettings.View"],
            parentId: 15,
          },
          {
            id: 19,
            label: "MENUITEMS.SETTINGS.CARGO-TYPES.TITLE",
            link: "Admin/settings/cargo-settings",
            Permissions: ["Permissions.ShipSettings.View"],
            parentId: 15,
          },
        ],
      },
    ],
  },
  {
    id: 25,
    label: "MENUITEMS.USERS.TITLE",
    icon: "ri-group-line",
    Permissions: ["Permissions.Users.View"],
    subItems: [
      {
        id: 26,
        label: "MENUITEMS.USERS.LIST",
        link: "Admin/users/users-list",
        Permissions: ["Permissions.Users.View"],
        parentId: 25,
      },
      {
        id: 27,
        label: "MENUITEMS.USERS.ROLES.LIST",
        link: "Admin/users/roles-list",
        Permissions: ["Permissions.Users.View"],
        parentId: 25,
      },
    ],
  },
  {
    id: 29,
    label: "MENUITEMS.REPORTS.TITLE",
    icon: "ri-file-chart-line",
    Permissions: ["Permissions.Reports.View"],
    subItems: [
      {
        id: 30,
        label: "MENUITEMS.REPORTS.LIST",
        link: "Admin/reports/services-reports",
        Permissions: ["Permissions.Reports.View"],
        parentId: 29,
      },
    ],
  },
  {
    id: 31,
    label: "MENUITEMS.SUPPORT_TICKETS.TITLE",
    icon: "ri-coupon-line",
    subItems: [
      {
        id: 32,
        label: "MENUITEMS.SUPPORT_TICKETS.LIST",
        link: "Admin/support-tickets/support-tickets-list",
        parentId: 31,
      },
    ],
  },
];
export const COMPANY_MENU: MenuItem[] = [
  {
    id: 1,
    label: "القائمة الرئيسية",
    isTitle: true,
  },
  {
    id: 2,
    label: "MENUITEMS.INVOICES.TITLE",
    icon: "ri-file-list-3-line",
    subItems: [
      {
        id: 3,
        label: "MENUITEMS.INVOICES.LIST",
        link: "Company/Invoices/company-invoices",
        parentId: 2,
      },
    ],
  },
  {
    id: 4,
    label: "MENUITEMS.ISSUANCES.TITLE",
    icon: "ri-refund-line",
    subItems: [
      {
        id: 5,
        label: "MENUITEMS.ISSUANCES.RECEIPTS.LIST",
        link: "Company/Issuances/company-receipts",
        parentId: 4,
      },
    ],
  },
  {
    id: 4,
    label: "MENUITEMS.DEPOSIT_VOUCHERS.TITLE",
    icon: "ri-refund-line",
    subItems: [
      {
        id: 5,
        label: "MENUITEMS.DEPOSIT_VOUCHERS.LIST",
        link: "Company/DepositVouchers/company-deposit-vouchers",
        parentId: 4,
      },
    ],
  },
  {
    id: 6,
    label: "MENUITEMS.MY_COMPANY.TITLE",
    icon: "ri-hotel-line",
    link: "Company/Profile/Personal-Profile",
  },
];
export const AUDITOR_MENU: MenuItem[] = [
  {
    id: 1,
    label: "القائمة الرئيسية",
    isTitle: true,
  },
  {
    id: 2,
    label: "MENUITEMS.DEPOSIT_VOUCHERS.TITLE",
    icon: "ri-refund-line",
    subItems: [
      {
        id: 3,
        label: "MENUITEMS.DEPOSIT_VOUCHERS.LIST",
        link: "Auditor/DepositVouchers/deposit-vouchers-list",
        parentId: 2,
      },
    ],
  },
];
export const VERIFIER_MENU: MenuItem[] = [
  {
    id: 1,
    label: "القائمة الرئيسية",
    isTitle: true,
  },
  {
    id: 2,
    label: "MENUITEMS.DEPOSIT_VOUCHERS.TITLE",
    icon: "ri-refund-line",
    subItems: [
      {
        id: 3,
        label: "MENUITEMS.DEPOSIT_VOUCHERS.LIST",
        link: "Verifier/DepositVouchers/deposit-vouchers-list",
        parentId: 2,
      },
    ],
  },
];
