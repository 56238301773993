import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { LoginModel, User } from "../models/auth.model";
import { ResponseResult } from "src/app/shared/models/ResponseResult.model";
import { BehaviorSubject, map, Observable, tap } from "rxjs";
import { CustomEncoder } from "src/app/shared/services/custom-encoder.service";
import { jwtDecode } from "jwt-decode";
@Injectable({
  providedIn: "root",
})
export class AuthService {
  private apiUrl = environment.apiUrl + "/api/Auth";
  public currentUserSubject: BehaviorSubject<User>;
  currentUserValue: any;
  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser") || "{}")
    );
    this.currentUserValue = JSON.parse(localStorage.getItem("currentUser")!);
  }
  public get currentUserSubjectValue(): User {
    return this.currentUserSubject.value;
  }
  login(model: LoginModel): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/Login`, model).pipe(
      tap((response) => {
        if (response.isSuccess) {
          localStorage.setItem("currentUser", JSON.stringify(response.data));
          this.currentUserSubject.next(response.data);
        }
      })
    );
  }
  confirmEmail(userId, token): Observable<any> {
    let params = new HttpParams({ encoder: new CustomEncoder() });
    params = params.append("userId", userId);
    params = params.append("token", token);
    return this.http.get<any>(`${this.apiUrl}/ConfirmEmail`, {
      params: params,
    });
  }
  resetPassword(email: string): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}/ResetPassword?email=${email}`,
      ``
    );
  }
  getUserType() {
    const currentUserString = localStorage.getItem("currentUser");
    if (currentUserString) {
      const currentUser = JSON.parse(currentUserString);
      if (currentUser) {
        return currentUser.userType;
      }
    }
    return null;
  }
  changeEmail(model: any): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}/ChangeUserEmail?userId=${model.userId}&newEmail=${model.newEmail}`,
      ``
    );
  }
  changePassword(model: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/ChangePassword`, model);
  }
  isLoggedIn(): boolean {
    const currentUserString = localStorage.getItem("currentUser");
    if (currentUserString) {
      const currentUser = JSON.parse(currentUserString);
      if (currentUser.token == null || currentUser.token == undefined) {
        return false;
      }
      if (currentUser && this.isTokenValid(currentUser.token)) {
        return true;
      }
    }
    return false;
  }
  isUserAdmin(): boolean {
    const currentUserString = localStorage.getItem("currentUser");
    if (currentUserString) {
      const currentUser = JSON.parse(currentUserString);
      if (currentUser && currentUser.userType == "0") {
        console.log(true);
        return true;
      }
    }
    return false;
  }
  isUserCompany(): boolean {
    const currentUserString = localStorage.getItem("currentUser");
    if (currentUserString) {
      const currentUser = JSON.parse(currentUserString);
      if (currentUser && currentUser.userType === 1) {
        console.log(true);
        return true;
      }
    }
    return false;
  }
  isUserAuditor(): boolean {
    const currentUserString = localStorage.getItem("currentUser");
    if (currentUserString) {
      const currentUser = JSON.parse(currentUserString);
      if (currentUser && currentUser.userType === 2) {
        return true;
      }
    }
    return false;
  }
  isUserVerifier(): boolean {
    const currentUserString = localStorage.getItem("currentUser");
    if (currentUserString) {
      const currentUser = JSON.parse(currentUserString);
      if (currentUser && currentUser.userType === 3) {
        return true;
      }
    }
    return false;
  }
  isTokenValid(token: string): boolean {
    const decoded = jwtDecode(token);

    if (decoded.exp === undefined) {
      return false; // No expiration time found
    }

    // Get Current Date Time
    const date = new Date(0);

    // Convert EXp Time to UTC
    let tokenExpDate = date.setUTCSeconds(decoded.exp);

    // If Value of Token time greter than

    if (tokenExpDate.valueOf() > new Date().valueOf()) {
      return true;
    }
    return false;
  }
  isEmailConfirmed(userId): Observable<any> {
    return this.http
      .get<ResponseResult>(`${this.apiUrl}/IsEmailConfirmed?userId=${userId}`)
      .pipe(map((response) => response.data));
  }
  VerifyEmail(userId): Observable<any> {
    return this.http
      .get<ResponseResult>(`${this.apiUrl}/VerifyEmail?userId=${userId}`)
      .pipe(map((response) => response.data));
  }
  logout() {
    localStorage.removeItem("currentUser");
  }
}
