<!-- start candidates -->
<section class="section bg-light" id="candidates">
    <div class="bg-overlay bg-overlay-pattern"></div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8">
                <div class="text-center mb-5">
                    <h1 class="mb-3 ff-secondary fw-semibold text-capitalize lh-base">Hire Experts <span
                            class="text-primary">Team</span></h1>
                    <p class="text-muted mb-4">Hiring experts costs more per hour than hiring entry- or mid-level
                        freelancers, but they can usually get the work done faster—and better.</p>
                </div>
            </div>
            <!-- end col -->
        </div>
        <!-- end row -->
        <div class="row">
            <div class="col-lg-12">
                <!-- Swiper -->
                <div class="swiper mySwiper pb-4">
                    <swiper [config]="Responsive">
                        <div class="swiper-wrapper">
                            <div *ngFor="let data of candidates" class="swiper-slide">
                                <div class="card text-center">
                                    <div class="card-body p-4">
                                        <img src="{{data.image}}" alt="" class="rounded-circle avatar-md mx-auto d-block">
                                        <h5 class="fs-17 mt-3 mb-2">{{data.name}}</h5>
                                        <p class="text-muted fs-13 mb-3">{{data.designation}}</p>
    
                                        <p class="text-muted mb-4 fs-14">
                                            <i class="ri-map-pin-2-line text-primary me-1 align-bottom"></i>
                                            {{data.location}}
                                        </p>
                                        <a href="#!" class="btn btn-primary w-100">View Profile</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-pagination swiper-pagination-dark"></div>
                    </swiper>
                </div>
            </div>
        </div>
    </div>
    <!-- end container -->
</section>
<!-- end candidates -->