import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-job-footer',
  templateUrl: './job-footer.component.html',
  styleUrls: ['./job-footer.component.scss']
})
export class JobFooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
