import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AuthenticationService } from "../services/auth.service";
import Swal from "sweetalert2";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        // Check if it is a 401 error
        if (error.status === 401) {
          // Reload the current page
          window.location.reload();
        }
        return throwError(error);
      }),
      catchError((err) => {
        console.log(err);
        if (err.status === 401) {
          Swal.fire({
            title: "فشلت العملية",
            text: ".ليس لديك صلاحية للقيام بهذه العملية",
            icon: "error",
            confirmButtonText: "موافق",
          });
        }
        const error = err.error.message || err.statusText;
        return throwError(error);
      })
    );
  }
}
