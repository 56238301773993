<div *ngIf="loading" class="loading-container flex-content-center">
  <div
    class="spinner-border text-info"
    role="status"
    style="position: absolute; top: 50%; right: 50%; z-index: 1000"
  >
    <span class="sr-only">Loading...</span>
  </div>
</div>

<router-outlet></router-outlet>
