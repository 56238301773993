<!-- ========== App Menu ========== -->
<div class="app-menu navbar-menu">
  <!-- LOGO -->
  <div class="navbar-brand-box">
      <!-- Dark Logo-->
      <a routerLink="/" class="logo logo-dark">
          <span class="logo-sm">
              <img src="assets/images/logo-sm.png" alt="" height="22">
          </span>
          <span class="logo-lg">
              <img src="assets/images/logo-dark.png" alt="" height="17">
          </span>
      </a>
      <!-- Light Logo-->
      <a routerLink="/" class="logo logo-light">
          <span class="logo-sm">
              <img src="assets/images/logo-sm.png" alt="" height="22">
          </span>
          <span class="logo-lg">
              <img src="assets/images/logo-light.png" alt="" height="17">
          </span>
      </a>
      <button type="button" class="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover" id="vertical-hover">
          <i class="ri-record-circle-line"></i>
      </button>
  </div>

  <div id="scrollbar">
      <div class="container-fluid">

          <div id="two-column-menu" >
            
            <ul class="twocolumn-iconview" data-simplebar="init">
                  <div class="simplebar-wrapper" style="margin: 0px;">
                    <div class="simplebar-mask">
                      <div class="simplebar-offset" style="right: 0px; bottom: 0px;">
                        <div class="simplebar-content-wrapper" tabindex="0" role="region" aria-label="scrollable content" style="height: 100%; overflow: hidden scroll;">
                          <div class="simplebar-content" style="padding: 0px;">
                            <a href="javascript:void(0);" class="logo">
                              <img src="assets/images/logo-sm.png" alt="" height="22">
                            </a>
                            <ng-container *ngFor="let item of menuItems">
                              <li *ngIf="!item.isTitle && !item.isLayout">
                                <a *ngIf="hasItems(item)" href="javascript:void(0);" [attr.subitems]="item.collapseid" class="nav-icon"
                                  (click)="toggleItem($event)">
                                  <i class="{{ item.icon }}" class=""></i>
                                  <span data-key="t-dashboards" class="d-none">{{item.label | translate}}</span>
                                </a>
                                <a *ngIf="!hasItems(item)" [routerLink]="item.link" [attr.subitems]="item.collapseid" class="nav-icon" (click)="toggleParentItem($event)">
                                  <i class="{{ item.icon }}" class=""></i>
                                  <span data-key="t-widget" class="d-none">{{item.label | translate}}</span>
                                </a>
                              </li>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ul>
          </div>

          <ul class="navbar-nav" id="navbar-nav" data-simplebar="init">
            <div class="simplebar-wrapper" style="margin: 0px;">
              <div class="simplebar-height-auto-observer-wrapper">
                <div class="simplebar-height-auto-observer"></div>
              </div>
              <div class="simplebar-mask">
                <div class="simplebar-offset" style="right: 0px; bottom: 0px;">
                  <ngx-simplebar class="simplebar-content-wrapper" style="max-height: 100vh">
                    <div class="simplebar-content" style="padding: 0px;">
                      <li class="menu-title"><span data-key="t-menu">Menu</span></li>
                      <li class="nav-item" *ngFor="let item of menuItems">
                        <ng-container >

                      <div class="collapse menu-dropdown" id="{{item.collapseid}}" [attr.data-name]="item.label | translate" aria-expanded="true">
                          <ul *ngIf="hasItems(item)" class="nav nav-sm flex-column" aria-expanded="false">
                            <li *ngFor="let subitem of item.subItems" class="nav-item">
                              <a [routerLink]="subitem.link" *ngIf="!hasItems(subitem)" class="nav-link"
                                [attr.data-parent]="subitem.parentId" (click)="updateActive($event)">
                                {{ subitem.label | translate}}
                                <span class="badge badge-pill {{subitem.badge.variant}}" *ngIf="subitem.badge">{{subitem.badge.text
                                  | translate}}</span>
                                
                              </a>
                              <a *ngIf="hasItems(subitem)" class="nav-link ecomm" data-bs-toggle="collapse" [attr.aria-expanded]="false" href="javascript:void(0);"
                                [attr.data-parent]="subitem.parentId" (click)="toggleSubItem($event)">
                                {{ subitem.label | translate}}
                                <span class="badge badge-pill {{subitem.badge.variant}}" *ngIf="subitem.badge">{{subitem.badge.text
                                  | translate}}</span>
                              </a>

                              <div class="collapse menu-dropdown sub-menu" *ngIf="hasItems(subitem)" id="{{item.collapseid}}">
                                <ul class="nav nav-sm flex-column">
                                  <li *ngFor="let subSubitem of subitem.subItems" class="nav-item">

                                    <a [attr.data-parent]="subSubitem.parentId" [routerLink]="subSubitem.link"
                                    class="nav-link" (click)="updateActive($event)" *ngIf="!hasItems(subSubitem)">
                                      {{ subSubitem.label | translate }}
                                      <span class="badge badge-pill {{subSubitem.badge.variant}}" *ngIf="subSubitem.badge">{{subSubitem.badge.text
                                        | translate}}</span>
                                    </a>

                                    <a *ngIf="hasItems(subSubitem)" class="nav-link" href="javascript:void(0);"
                                      [attr.data-parent]="subSubitem.parentId" data-bs-toggle="collapse" aria-expanded="false" (click)="toggleExtraSubItem($event)">
                                      {{ subSubitem.label | translate}}
                                      <span class="badge badge-pill {{subSubitem.badge.variant}}" *ngIf="subSubitem.badge">{{subSubitem.badge.text
                                        | translate}}</span>
                                    </a>

                                    <div class="collapse menu-dropdown extra-sub-menu" *ngIf="hasItems(subSubitem)">
                                      <ul *ngIf="hasItems(subSubitem)" class="nav nav-sm flex-column" aria-expanded="false">
                                        <li *ngFor="let subSubitem1 of subSubitem.subItems" class="nav-item">
        
                                          <a [attr.data-parent]="subSubitem1.parentId" [routerLink]="subSubitem1.link"
                                          class="nav-link" (click)="updateActive($event)">
                                            {{ subSubitem1.label | translate }}
                                          </a>
                                          
                                        </li>
                                      </ul>
                                    </div>
                                    
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </ng-container>
                      </li> <!-- end Dashboard Menu -->
                    </div>
                  </ngx-simplebar>
                </div>
              </div>
              <div class="simplebar-placeholder" style="width: auto; height: 183px;"></div>
            </div>
          </ul>
      </div>
      <!-- Sidebar -->
  </div>
  <div class="sidebar-background"></div>
</div>
<!-- Left Sidebar End -->
<!-- Vertical Overlay-->
<div class="vertical-overlay"></div>
