<header id="page-topbar" data-scroll-header (window:scroll)="windowScroll()">
  <div class="layout-width">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box horizontal-logo">
          <a routerLink="" class="logo logo-dark">
            <span class="logo-sm">
              <img src="assets/images/logo-sm.png" alt="" height="22" />
            </span>
            <span class="logo-lg">
              <img src="assets/images/logo-dark.png" alt="" height="17" />
            </span>
          </a>

          <a routerLink="" class="logo logo-light">
            <span class="logo-sm">
              <img src="assets/images/logo-sm.png" alt="" height="22" />
            </span>
            <span class="logo-lg">
              <img src="assets/images/logo-light.png" alt="" height="17" />
            </span>
          </a>
        </div>

        <button
          type="button"
          class="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
          id="topnav-hamburger-icon"
          (click)="toggleMobileMenu($event)"
        >
          <span class="hamburger-icon">
            <span></span>
            <span></span>
            <span></span>
          </span>
        </button>
      </div>

      <div class="d-flex align-items-center">
        <div class="dropdown ms-sm-3 header-item topbar-user" ngbDropdown>
          <button
            type="button"
            class="btn"
            id="page-header-user-dropdown"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            ngbDropdownToggle
          >
            <span class="d-flex align-items-center">
              <img
                class="rounded-circle header-profile-user"
                src="assets/images/users/user-dummy-img.jpg"
                alt="Header Avatar"
              />
              <span class="text-start ms-xl-2">
                <span
                  class="d-none d-xl-inline-block ms-1 fw-medium user-name-text"
                  >{{ userData.name }}
                </span>
                <span
                  class="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text"
                  >{{ userType }}</span
                >
              </span>
            </span>
          </button>
          <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
            <!-- item-->
            <div *ngIf="userType == 'شركة'">
              <a
                class="dropdown-item"
                routerLink="/Company/Profile/Personal-Profile"
                ><i
                  class="mdi mdi-account-circle text-muted fs-16 align-middle me-1"
                ></i>
                <span class="align-middle">الصفحة الشخصية</span></a
              >
              <div class="dropdown-divider"></div>
            </div>

            <a
              class="dropdown-item"
              href="javascript: void(0);"
              (click)="logout()"
              ><i class="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>
              <span class="align-middle" data-key="t-logout"
                >تسجيل الخروج</span
              ></a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
