const ClientLogo = [
  {
    images: [
      {
        image: [{ img: 'assets/images/nft/img-06.jpg' }, { img: 'https://img.themesbrand.com/velzon/images/img-2.gif' }]
      },
      {
        image: [{ img: 'https://img.themesbrand.com/velzon/images/img-5.gif' }, { img: 'assets/images/nft/img-03.jpg' }]
      },
    ],
  },
  {
    images: [
      {
        image: [{ img: 'assets/images/nft/img-05.jpg' }, { img: 'https://img.themesbrand.com/velzon/images/img-1.gif' }]
      },
      {
        image: [{ img: 'https://img.themesbrand.com/velzon/images/img-4.gif' }, { img: 'assets/images/nft/img-04.jpg' }]
      },
    ],
  },
  {
    images: [
      {
        image: [{ img: 'assets/images/nft/img-02.jpg' }, { img: 'https://img.themesbrand.com/velzon/images/img-3.gif' }]
      },
      {
        image: [{ img: 'https://img.themesbrand.com/velzon/images/img-1.gif' }, { img: 'assets/images/nft/img-01.jpg' }]
      },
    ],
  },
  {
    images: [
      {
        image: [{ img: 'assets/images/nft/img-03.jpg' }, { img: 'https://img.themesbrand.com/velzon/images/img-5.gif' }]
      },
      {
        image: [{ img: 'https://img.themesbrand.com/velzon/images/img-2.gif' }, { img: 'assets/images/nft/img-05.jpg' }]
      },
    ],
  },
  {
    images: [
      {
        image: [{ img: 'assets/images/nft/img-02.jpg' }, { img: 'https://img.themesbrand.com/velzon/images/img-3.gif' }]
      },
      {
        image: [{ img: 'https://img.themesbrand.com/velzon/images/img-1.gif' }, { img: 'assets/images/nft/img-01.jpg' }]
      },
    ],
  },
];

export { ClientLogo };
