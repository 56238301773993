<table class="table table-bordered table-nowrap align-middle mb-0">
    <thead>
        <tr>
            <th scope="col" style="width: 30%;">Deal Name</th>
            <th scope="col" style="width: 30%;">Sales Rep</th>
            <th scope="col" style="width: 20%;">Amount</th>
            <th scope="col" style="width: 20%;">Close Date</th>
        </tr>
    </thead>

    <tbody>
        <tr *ngFor="let data of ClosingDeals">
            <td>{{data.name}}</td>
            <td><img src="{{data.profile}}" alt="" class="avatar-xs rounded-circle me-2">
                <a href="javascript:void(0);" class="text-body fw-medium">{{data.userName}}</a></td>
            <td>${{data.amount}}</td>
            <td>{{data.date}}</td>
        </tr>
    </tbody><!-- end tbody -->
</table><!-- end table -->
