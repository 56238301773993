<!-- start review -->
<section class="section bg-primary" id="reviews">
    <div class="bg-overlay bg-overlay-pattern"></div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-10">
                <div class="text-center">
                    <div>
                        <i class="ri-double-quotes-l text-success display-3"></i>
                    </div>
                    <h4 class="text-white mb-5"><span class="text-success">19k</span>+ Satisfied clients</h4>

                    <!-- Swiper -->
                    <div class="swiper navigation-swiper rounded testimonial-slider">
                        <swiper [config]="review">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide" *ngFor="let data of ClientLogo">
                                    <div class="row justify-content-center g-0">
                                        <div class="col-10">
                                            <div class="text-white-50">
                                                <p class="fs-20 ff-secondary mb-4">" I am givng 5 stars. Theme is great and everyone one stuff everything in theme. Future request should not affect current
                                                state of theme. "</p>
                        
                                                <div>
                                                    <h5 class="text-white">gregoriusus</h5>
                                                    <p>- Skote User</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              <!-- end slide -->
                            </div>
                        </swiper>
                        <div class="swiper-pagination position-relative mt-2"></div>
                    </div>
                    <!-- end slider -->
                </div>
            </div>
            <!-- end col -->
        </div>
        <!-- end row -->
    </div>
    <!-- end container -->
</section>
<!-- end review -->